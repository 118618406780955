<template>
  <div>
    <!-- Purchase Opt Options -->
    <div class="d-flex align-center">
        <label class="mr-3">Purchase Email opt-in/opt-out:</label>
        <v-switch
        v-model="localPurchasePolicyOpt"
        @change="updateValue('purchasePolicyOpt', localPurchasePolicyOpt)"
        >
        </v-switch>
        <validation-provider
        v-if="localPurchasePolicyOpt"
        v-slot="{ errors }"
        name="emailOptionsForPolicyPurchase"
        rules="required"
        >
        <div
        class="d-flex align-center"
        v-if="localPurchasePolicyOpt"
        >
        <v-radio-group
        v-model="localPurchaseEmailOptions"
        name="purchaseEmailOptions"
        row
        :error-messages="errors"
        @change="updateValue('purchaseEmailOptions', localPurchaseEmailOptions)"
        class="radiogroup-style"
        >
        <v-radio
            value="CC"
            label="CC"
        />
        <v-radio
            value="BCC"
            label="BCC"
        />
        </v-radio-group>
        </div>
        </validation-provider>
    </div>
    <div class="d-flex align-center">
        <label class="mr-3">Knockout Email opt-in/opt-out:</label>
        <v-switch
        v-model="localKnockOutOptOption"
        @change="updateValue('knockOutOptOption', localKnockOutOptOption)"
        >
        </v-switch>
        <validation-provider
        v-if="localKnockOutOptOption"
        v-slot="{ errors }"
        name="emailOptionsForKnockout"
        rules="required"
        >
        <div
        class="d-flex align-center"
        v-if="localKnockOutOptOption"
        >
        <v-radio-group
        v-model="localKnockoutEmailOptions"
        name="knockoutEmailOptions"
        row
        :error-messages="errors"
        @change="updateValue('knockoutEmailOptions', localKnockoutEmailOptions)"
        class="radiogroup-style"
        >
        <v-radio
            value="CC"
            label="CC"
        />
        <v-radio
            value="BCC"
            label="BCC"
        />
        </v-radio-group>
        </div>
        </validation-provider>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      purchasePolicyOpt: Boolean,
      knockOutOptOption: Boolean,
      purchaseEmailOptions: String,
      knockoutEmailOptions: String
    },
    data () {
      return {
        localPurchasePolicyOpt: this.purchasePolicyOpt,
        localKnockOutOptOption: this.knockOutOptOption,
        localPurchaseEmailOptions: this.purchaseEmailOptions,
        localKnockoutEmailOptions: this.knockoutEmailOptions
      }
    },
    watch: {
      purchasePolicyOpt (newVal) {
        if (!newVal) {
          this.localPurchaseEmailOptions = null
          this.updateValue('purchaseEmailOptions', this.localPurchaseEmailOptions)
        } else {
          this.localPurchasePolicyOpt = newVal
          this.localPurchaseEmailOptions = this.purchaseEmailOptions
          this.updateValue('purchaseEmailOptions', this.localPurchaseEmailOptions)
        }
      },
      knockOutOptOption (newVal) {
        if (!newVal) {
          this.localKnockoutEmailOptions = null
          this.updateValue('knockoutEmailOptions', this.localKnockOutOptOption)
        } else {
          this.localKnockOutOptOption = newVal
          this.localKnockoutEmailOptions = this.knockoutEmailOptions
          this.updateValue('knockoutEmailOptions', this.localKnockoutEmailOptions)
        }
      }
    },
    mounted () {
      if (!this.localPurchasePolicyOpt) {
        this.localPurchaseEmailOptions = null
      }
      if (!this.localKnockOutOptOption) {
        this.localKnockoutEmailOptions = null
      }
    },
    methods: {
      updateValue (prop, value) {
        this.$emit('update', { prop, value })
      }
    }
  }
</script>

<style scoped>
  .d-flex {
    display: flex;
    align-items: center;
  }
  ::v-deep .d-flex label{
    top: 5px;
    color: #000;
  }
  .mr-3 {
    margin-right: 1rem;
  }
  .radiogroup-style {
    margin-left: 30px;
  }
</style>
